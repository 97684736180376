<template>
  <v-container>
    
    <v-form v-model="valid">
      <v-card
        class="pa-md-4 mx-lg-auto"
        outlined
      >
        <v-row>
          <v-col
            cols="12"
            md="12"
          >
            <v-dialog
                ref="SelDate"
                v-model="menuSelDate"
                :return-value.sync="txtDatein"
                persistent
                width="290px"
              >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="txtDatein"
                  label="Pilih Bulan/Tahun"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-on="on"
                ></v-text-field>
              </template>
                <v-date-picker 
                v-model="txtDatein"
                >
                <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menuSelDate = false">Cancel</v-btn>
                  <v-btn text color="primary" @click="$refs.SelDate.save(txtDatein)">OK</v-btn>
                </v-date-picker>
                
            </v-dialog>
            
          </v-col>
          <v-col
          cols="12"
            md="12"
          >
            <v-select
            :items="dataPos"
            name="value"
            label="Pilih Jabatan"
            v-model="selpositions"
            item-text="label"
            ></v-select>
      
          </v-col>

          <v-col
            cols="12"
            md="12"
          >
            <v-textarea
            outlined
            name="input-7-4"
            label="Isi Laporan Pekerjaan"
            v-model="txtLaporan"
          ></v-textarea>
          </v-col>

          <v-col
            cols="12"
            md="12"
          >
            <v-file-input
              v-model="attachment.file"
              placeholder="Upload Dokumen Pekerjaan"
              label="File input"
              show-size
              single
              prepend-icon="mdi-paperclip"
              accept=".xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf"
            >
              <template v-slot:selection="{ text }">
                <v-chip
                  small
                  label
                  color="primary"
                >
                  {{ text }}
                </v-chip>
              </template>
            </v-file-input>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn
              color="error"
              class="mr-4"
              @click="goBack()"
            >
              Batal
            </v-btn>

            <v-btn v-if="!isEdit" :disabled="isLoading"
              color="primary"
              @click="saveAddForm()"
            >
              Simpan
            </v-btn>
            <v-btn v-else :disabled="isLoading"
              color="primary"
              @click="saveEditForm()"
            >
              Ubah
            </v-btn>
      
          </v-col>
        </v-row>
      </v-card>
    </v-form>
    <v-snackbar
        v-model="toast.show"
        :timeout="toast.timeout"
      >
        {{ toast.text }}
        <v-btn
          :color="toast.color"
          text
          @click="toast.show = false"
        >
          Close
        </v-btn>
      </v-snackbar>

      <v-dialog v-model="isLoading" fullscreen>
          <v-container fluid fill-height style="background-color: rgba(0, 0, 0, 0.8);">
              <v-layout justify-center align-center>
                <v-card
                  width="70vW"
                  class="pa-md-2 mx-lg-auto"
                  outlined
                >
                  <v-list-item three-line centered>
                    <v-list-item-content>
                      <div class="overline mb-4">Processing</div>
                      <v-list-item-title class="headline mb-1">Please Wait....</v-list-item-title>
                      <v-list-item-subtitle>
                        <v-progress-linear
                          indeterminate
                          color="yellow darken-2"
                        ></v-progress-linear>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>

              </v-layout>
          </v-container>
      </v-dialog>

      <v-dialog v-model="isupLoading" fullscreen>
          <v-container fluid fill-height style="background-color: rgba(0, 0, 0, 0.8);">
              <v-layout justify-center align-center>
                <v-card
                  width="70vW"
                  class="pa-md-2 mx-lg-auto"
                  outlined
                >
                  <v-list-item three-line>
                    <v-list-item-content>
                      <div class="overline mb-4">Uploading File</div>
                      <v-list-item-title class="headline mb-1">Please Wait....</v-list-item-title>
                      <v-list-item-subtitle>
                        <v-progress-linear
                            v-model="uploadPercentage"
                            height="25"
                            reactive
                          >
                          <strong>{{ Math.ceil(uploadPercentage) }}%</strong>
                        </v-progress-linear>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>

              </v-layout>
          </v-container>
      </v-dialog>

      <v-dialog v-model="dialogConfirm" persistent max-width="350">
        <v-card>
          <v-card-title class="headline">Konfirmasi</v-card-title>
          <v-card-text>Apakah anda yakin data tersebut sudah benar?</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="warning darken-1" text @click="dialogConfirm = false">Batal</v-btn>
            <v-btn v-if="!isEdit" color="blue darken-1" @click="insertData()">Submit</v-btn>
            <v-btn v-else color="blue darken-1" @click="updateData()">Submit</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
</v-container>
</template>

<script>
import { getLaporanByID, getUserPosByUserID, insertLaporanWFH, updateLaporanWFH } from '@/api/laporanwfh'
import { _GLOBAL } from '@/api/server'
import moment from 'moment'
import axios from 'axios'

const defaultForm = {
  id: 0,
  user: 0,
  UserNIK: 0,
  dateIN: '',
  JobTask: '',
  notes: '',
  status: '',
  hruserposition: 0,
  created_at: new Date(),
  updated_at: new Date()
}

export default {
  name: 'FormLaporanWFH',
  components: {
  },
  props: {
    isEdit: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      postForm: Object.assign({}, defaultForm),
      toast: { show: false, color:'green', text:'', timeout: 2000 },
      valid: false,
      uploadPercentage: 0,
      isupLoading: false,
      menuSelDate: false,
      isLoading: false,
      dataUser: null,
      txtDatein: moment().format('YYYY-MM-DD'),
      txtLaporan: '',
      selpositions: 0,
      fileUpload: null,
      attachment: {
        name: '',
        file: null,
        url: ''
      },
      err: {
        date: '',
        lap: '',
        file: '',
        pos: ''
      },
      dataPos: [],
      dialogConfirm: false
    }
  },
  created () {
    if (this.isEdit) {
      const id = this.$route.params && this.$route.params.id
      this.fetchData(id)
    }
    else {
      this.postForm = Object.assign({})
    }
  },
  destroyed () {
  },
  mounted () {
   
    var sUser = localStorage.getItem('user')
    this.dataUser = JSON.parse(sUser)
    // var now = moment().format('YYYY-MM-DD')
    this.getUserPositionDataByUserID()
    
  },
  methods: {
    goBack () {
      this.$router.go(-1)
    },
    saveAddForm () {
      const vm = this
      vm.err.date = ''
      vm.err.lap = ''
      vm.err.file = ''
      vm.err.pos = ''

      console.log('txtLaporan: ', vm.txtLaporan)

      if (!moment(new Date(vm.txtDatein)).isValid()) {
        vm.err.date = 'has-error'
        vm.toast = {
          show: true, color:'red', text: 'Format Tanggal Laporan pekerjaan salah!', timeout: 2000
        }
        return false
      }

      if (vm.selpositions === null || vm.selpositions === '') {
        vm.err.pos = 'has-error'
        vm.toast = {
          show: true, color:'red', text: 'Jabatan Laporan pekerjaan wajib isi!', timeout: 2000
        }
        return false
      }

      if (vm.txtLaporan === null || vm.txtLaporan === '') {
        vm.err.lap = 'has-error'
        vm.toast = {
          show: true, color:'red', text: 'Isi Laporan pekerjaan wajib isi!', timeout: 2000
        }
        
        return false
      }

      // console.log(vm.attachment)
      if (vm.attachment.file === null) {
        vm.err.file = 'has-error'
        vm.toast = {
          show: true, color:'red', text: 'File upload Laporan wajib isi!', timeout: 2000
        }
        
        return false
      }

      vm.dialogConfirm = true
    },
    saveEditForm () {
      const vm = this
      vm.err.date = ''
      vm.err.lap = ''
      vm.err.file = ''
      vm.err.pos = ''

      if (!moment(new Date(vm.txtDatein)).isValid()) {
        vm.err.date = 'has-error'
        vm.toast = {
          show: true, color:'red', text: 'Format Tanggal Laporan pekerjaan salah!', timeout: 2000
        }
        return false
      }

      if (vm.selpositions === null || vm.selpositions === '') {
        vm.err.pos = 'has-error'
        vm.toast = {
          show: true, color:'red', text: 'Jabatan Laporan pekerjaan wajib isi!', timeout: 2000
        }
        return false
      }

      if (vm.txtLaporan === null || vm.txtLaporan === '') {
        vm.err.lap = 'has-error'
        vm.toast = {
          show: true, color:'red', text: 'Isi Laporan pekerjaan wajib isi!', timeout: 2000
        }
        
        return false
      }

      vm.dialogConfirm = true
    },
    getUserPositionDataByUserID () {
      const vm = this
      var usr = vm.dataUser
      if (usr.id === null || usr.id === 0) {
        console.log('userid null')
        return false
      }
      vm.isLoading = true
      getUserPosByUserID(usr.id).then(response => {
        var data = response.data
        var arrtemp = []
        console.log('getUserPosByUserID', data)
        if (data.length > 0) {
          data.forEach(element => {
            if (element.hrposition) {
              if (element.hrposition.isActive === true) {
                var prefix = element.prefix ? element.prefix + ' ' : ''
                var tmp = { value: element.id, label: prefix + element.hrposition.pos_name }
                arrtemp.push(tmp)

                if (element.IsMainPosition) {
                  vm.selpositions = element.id
                }
              }
            }
          })
        }
        vm.dataPos = arrtemp

        vm.isLoading = false
      }).catch(err => {
        console.log(err)
        vm.isLoading = false
      })
    },
    fetchData (id) {
      const vm = this
      vm.isLoading = true
      getLaporanByID(id).then(response => {
        this.postForm = response.data
        console.log('getLaporanByID', this.postForm)
        vm.txtDatein = moment(vm.postForm.dateIN).format('YYYY-MM-DD')
        vm.txtLaporan = vm.postForm.JobTask
        if (vm.postForm.hruserposition) {
          vm.postForm.hruserposition = vm.postForm.hruserposition.id
          vm.selpositions = vm.postForm.hruserposition
        }
        delete this.postForm.created_at
        delete this.postForm.updated_at
        vm.isLoading = false
      }).catch(err => {
        console.log(err)
        vm.isLoading = false
      })
    },
    insertData () {
      const vm = this
      vm.dialogConfirm = false
      var usr = vm.dataUser
      var now = moment().add(7, 'hours').format('YYYY-MM-DD HH:mm:ss')
      var tgl = moment(vm.txtDatein).add(7, 'hours').format('YYYY-MM-DD HH:mm:ss')
      // console.log(usr)
      var insData = {
        user: usr.id,
        UserNIK: usr.nik,
        dateIN: tgl,
        JobTask: vm.txtLaporan,
        hruserposition: vm.selpositions,
        status: 'submitted',
        created_at: now,
        updated_at: now
      }
      // console.log('insertData: ', insData)
      
      vm.isLoading = true
      if(vm.postForm.JobTask == null){
        console.log('insert:',vm.postForm)
        insertLaporanWFH(insData).then(response => {
          // console.log('insertLaporanWFH', response.data)
          var data = response.data
          vm.postForm = data
          vm.txtDatein = vm.postForm.dateIN
          vm.txtLaporan = vm.postForm.JobTask
          if (vm.postForm.hruserposition !== null) {
            vm.postForm.hruserposition = vm.postForm.hruserposition.id
            vm.selpositions = vm.postForm.hruserposition
          }
          delete this.postForm.created_at
          delete this.postForm.updated_at

          if (vm.attachment.file !== null) {
            vm.uploadAttachment()
          }
          vm.isLoading = false
          vm.toast = {
            show: true, color:'green', text: 'Submit Laporan BERHASIL.', timeout: 2000
          }
        })
        .catch(err => {
          console.log(err)
          vm.isLoading = false
          vm.toast = {
            show: true, color:'red', text: 'Submit Laporan GAGAL, silakan dicoba kembali.', timeout: 2000
          }
        })
      }else{
        vm.updateData()
      }
    },
    updateData () {
      const vm = this
      vm.dialogConfirm = false
      var usr = vm.dataUser
      var now = moment().add(7, 'hours').format('YYYY-MM-DD HH:mm:ss')
      var tgl = moment(vm.txtDatein).add(7, 'hours').format('YYYY-MM-DD HH:mm:ss')
      var updData = {
        user: usr.id,
        UserNIK: usr.nik,
        dateIN: tgl,
        JobTask: vm.txtLaporan,
        hruserposition: vm.selpositions,
        updated_at: now
      }
      // console.log('updateData: ', updData)
      var idb = vm.postForm.id
      // console.log('id LaporanWFH: ', idb)
      vm.isLoading = true
      console.log('update:',vm.postForm)
      updateLaporanWFH(updData, idb).then(response => {
        // console.log('updateLaporanWFH', response.data)
        var data = response.data
        vm.postForm = data
        if (vm.attachment.file !== null) {
          vm.uploadAttachment()
        }
        else {
          vm.$router.push('/repwork/list')
        }
        vm.isLoading = false
        vm.toast = {
          show: true, color:'green', text: 'Update Laporan BERHASIL.', timeout: 2000
        }
      })
      .catch(err => {
        console.log(err)
        vm.isLoading = false
        vm.toast = {
          show: true, color:'red', text: 'Update Laporan GAGAL, silakan dicoba kembali.', timeout: 2000
        }
      })
    },
    beforeFileUpload (file) {
      /*
      var isFormatOK = false
      if (file.type === 'image/jpeg' || file.type === 'image/png') {
        isFormatOK = true
      }
      const isLt2M = file.size / 1024 / 1024 < 2
      if (!isFormatOK) {
        this.$message.error('Avatar picture must be JPG format!')
      }
      if (!isLt2M) {
        this.$message.error('Avatar picture size can not exceed 2MB!')
      }
      return isFormatOK && isLt2M
      */
      return true
    },
    filesChange (uploadfile) {
      this.attachment.file = uploadfile[0]
      // console.log(this.attachment.file)
    },
    addAttachment (file, fileList) {
      this.attachment.file = file
      // console.log(this.attachment.file)
    },
    uploadAttachment () {
      const vm = this
      console.log('uploadAttachment',vm.attachment)
      if (vm.attachment.file) {
        var file = vm.attachment.file
        if (vm.beforeFileUpload(file)) {
          if (vm.postForm.id !== 0) {
            vm.uploadFileAttachment(vm.postForm.id, file)
          }
          else {
            vm.$router.push('/repwork/list')
          }
        }
      }
    },
    uploadFileAttachment (refID, file) {
      const vm = this
      console.log('uploadFileAttachment')
      var token = localStorage.getItem('jwt')
      if (token.length === 0) return null

      const bodyFormData = new FormData()
      bodyFormData.append('refId', refID)
      bodyFormData.append('field', 'attachment')
      bodyFormData.append('ref', 'laporanWFH')
      // bodyFormData.append('source', 'laporanWFH')
      bodyFormData.append('path', 'laporanWFH/attachment')
      // Add each file upload to the form data
      bodyFormData.append('files', file, file.name)
      // console.log(file.name)
      var uri = _GLOBAL.URLBACKEND + '/upload'
      
      vm.isupLoading = true
      vm.uploadPercentage = 0
      axios.post(uri,
        bodyFormData,
        {
          headers: {
              'Content-Type': 'multipart/form-data',
              'Authorization': `Bearer ${token}`
          },
          onUploadProgress: function( progressEvent ) {
            vm.uploadPercentage = parseInt( Math.round( ( progressEvent.loaded / progressEvent.total ) * 100 ))
          }.bind(vm)
        }
      ).then(function(response){
        console.log('SUCCESS!!');
        console.log(response.data)

        vm.isupLoading = false
        vm.toast = {
          show: true, color:'green', text: 'File Laporan telah BERHASIL tersimpan.', timeout: 2000
        }
        vm.$router.push('/repwork/list')
      })
      .catch(function(err){
        console.log('FAILURE!!');
        console.log(err)
          vm.isupLoading = false
          vm.toast = {
            show: true, color:'red', text: 'File Laporan GAGAL tersimpan, silakan dicoba kembali.', timeout: 2000
          }
      });
    }
  }
}

</script>
