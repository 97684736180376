<template>
  <transition name="menu">
    <form-laporan :is-edit="false"/>
  </transition>
</template>

<script>
import formLaporan from './components/formLaporan'

export default {
  name: 'CreateFormLaporan',
  components: { formLaporan }
}
</script>

